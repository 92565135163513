<template>
  <div>
    <!-- <div class="d-flex">
      <h4 class="mb-0">Social Links</h4>
    </div> -->

    <!-- Form: Personal Info Form -->
    <b-form class="">
      <!-- Form: Personal Info Form -->

      <div class="simple-spinner" v-if="!isLoaded">
        <span></span>
      </div>

      <b-form ref="form" @submit.prevent="repeateAgain" v-else>
        <h5 v-if="socialLinks.length === 0" class="text-center pt-3 pb-3">No social links listed</h5>

        <b-row v-for="(item, index) in socialLinks" :id="item.id" :key="item.id" ref="row">
          <!-- Item Name -->
          <b-col lg="12" md="12" sm="12">
            <b-row>
              <b-col lg="4" md="4" sm="4">
                <b-form-group label="Label" :label-for="`social-label-${index}`">
                  <b-form-input :id="`social-label-${index}`" type="text" placeholder="LinkedIn" v-model="item.label" />
                </b-form-group>
              </b-col>
              <b-col lg="7" md="7" sm="7">
                <b-form-group label="URL" :label-for="`social-url-${index}`">
                  <b-form-input
                    :id="`social-url-${index}`"
                    type="url"
                    placeholder="https://www.linkedin.com/in/ishan-jadhwani"
                    v-model="item.url"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="1" md="1" sm="1">
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  style="padding-left: 10px; padding-right: 7px; padding-top: 11px; padding-bottom: 11px"
                  class="mt-0 mt-md-2 btn-sm"
                  @click="removeItem(index)"
                >
                  <feather-icon icon="XIcon" class="mr-25" />
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button variant="primary" class="float-right" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="doSaveChanges">
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Add New</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormCheckbox,
  BButtonGroup,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onMounted, onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useStudents from '../../useStudents';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import studentStoreModule from '@/store/student/studentStoreModule';
import moment from 'moment';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    BButtonGroup,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    studentData: {
      type: Object,
      required: true,
    },
    // profileData: {
    //   type: Object,
    //   required: true,
    // },
  },
  watch: {
    studentData: function () {},
  },
  data: () => {
    return {
      socialLinks: [],
      deletePrompt: false,
      isLoaded: false,
    };
  },
  methods: {
    doSaveChanges(cb) {
      const pwd = this.studentData;
      const id = pwd.id;

      this.socialLinks = this.socialLinks.filter((add) => {
        if (!add.url) return false;
        return true;
      });

      store
        .dispatch('studentStoreModule/updateStudentProfile', {
          id,
          profileData: { socialLinks: this.socialLinks },
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Student Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });

          this.loadProfile();

          if (typeof cb === 'function') {
            cb();
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    repeateAgain() {
      this.socialLinks.push({ label: '', url: '' });
    },
    removeItem(index) {
      this.socialLinks.splice(index, 1);
    },
    loadProfile() {
      store.dispatch('studentStoreModule/fetchStudentProfile', { id: this.studentDataInfo.id }).then((response) => {
        this.socialLinks = response.data.socialLinks;
        this.isLoaded = true;
        console.log(`Sociales registered`, this.socialLinks);
      });
    },
  },
  mounted() {
    this.loadProfile();
  },
  setup(props) {
    const studentDataInfo = ref(props.studentData);

    const { statusOptions } = useStudents();

    return {
      studentDataInfo,
      statusOptions,
      moment,
    };
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
