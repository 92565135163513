<template>
  <div>
    <!-- Header: Personal Info -->
    <!-- <div class="d-flex">
      <feather-icon icon="StudentIcon" size="19" />
      <h4 class="mb-0">Additional Information</h4>
    </div> -->

    <!-- Form: Personal Info Form -->
    <b-form class="">
      <!-- Form: Add Inf -->
      <b-row>
        <!-- Field: Program -->
        <b-col cols="12" lg="3" md="6" xs="12">
          <b-form-group label="Program" label-for="student-program">
            <v-select
              id="student-program"
              :value="attributes.program"
              :options="fundingSourceOptions"
              class="w-100"
              :clearable="false"
              :reduce="(val) => val.value"
              @input="(val) => (attributes.program = val)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button variant="primary" class="float-right" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="doSaveChanges">
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>

          <b-form-checkbox v-model="submit.sync" class="float-right mr-5" style="margin-top: 7px" input-id="submit-sync" disabled>
            Sync
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BFormCheckbox } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useStudents from '../../useStudents';
import studentStoreModule from '@/store/student/studentStoreModule';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    ToastificationContent,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    studentData: function () {},
  },
  data: () => {
    return {
      submit: {
        sync: false,
      },
      profileData: {},
      attributes: {
        program: 'open',
      },
    };
  },
  methods: {
    doSaveChanges() {
      const pwd = this.profileData;
      const id = this.studentData.id;

      const pl = { attributes: this.attributes };

      store
        .dispatch('studentStoreModule/updateStudentProfile', { id, profileData: pl })
        .then((response) => {
          this.profileData = response.data;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Student Profile Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });

          this.loadProfile();
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
    loadProfile() {
      store.dispatch('studentStoreModule/fetchStudentProfile', { id: this.studentDataInfo.id }).then((response) => {
        this.profileData = response.data;
        if (this.profileData.attributes) {
          this.attributes = this.profileData.attributes;
        }
      });
    },
  },
  mounted() {
    this.loadProfile();
  },
  setup(props) {
    const studentDataInfo = ref(props.studentData);

    const { statusOptions, clearanceOptions, relocatableOptions, remoteOptions, fundingSourceOptions } = useStudents();

    return {
      studentDataInfo,
      statusOptions,
      clearanceOptions,
      relocatableOptions,
      remoteOptions,
      fundingSourceOptions,
    };
  },
};
</script>

<style lang="scss"></style>
