<template>
  <div>
    <!-- Header: Personal Info -->
    <!-- <div class="d-flex">
      <h4 class="mb-0">Student Information</h4>
    </div> -->

    <!-- Form: Personal Info Form -->
    <b-form class="">
      <!-- Form: Personal Info Form -->
      <b-row v-if="studentSecretInfo" class="mt-1">
        <!-- Field: Student First Name -->
        <b-col cols="12" lg="3" md="6" xs="12">
          <div>
            <b-form-group label="SSN" label-for="student-ssn">
              <cleave
                id="student-ssn"
                v-model="studentSecretInfo.ssn"
                class="form-control"
                :raw="false"
                required
                :options="{ blocks: [3, 2, 4], numericOnly: true, delimiter: '-' }"
                autocomplete="off"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row v-else></b-row>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button variant="primary" class="float-right" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="updateAccount">
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>

          <!-- <b-form-checkbox v-model="submit.sync" class="float-right mr-5" style="margin-top: 7px" input-id="submit-sync" disabled>
            Sync
          </b-form-checkbox> -->
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BFormCheckbox } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useStudents from '../../useStudents';
import studentStoreModule from '@/store/student/studentStoreModule';
import { useToast } from 'vue-toastification/composition';
import Cleave from 'vue-cleave-component';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    ToastificationContent,
    Cleave,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const studentSecretInfo = ref(undefined);
    const ssnShow = ref(false);

    const toast = useToast();

    const updateAccount = async () => {
      store
        .dispatch('studentStoreModule/updateStudentProfile', {
          id: router.currentRoute.params.id,
          profileData: {
            secret: { ssn: studentSecretInfo.value.ssn },
          },
        })
        .then((response) => {
          const { data } = response;
          // studentSecretInfo.value = data.secret;
          fetchAccount();

          this.$emit('update:student-data', response.data);

          toast({
            component: ToastificationContent,
            props: {
              title: 'Student Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            studentSecretInfo.value = undefined;
          }

          toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    };

    const fetchAccount = async () => {
      store
        .dispatch('studentStoreModule/fetchStudentProfile', {
          id: router.currentRoute.params.id,
          queryParams: { populate: 'student profile cohort', secret: true, ssn: 'visible' },
        })
        .then((response) => {
          const { data } = response;
          studentSecretInfo.value = data.secret;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            studentSecretInfo.value = undefined;
          }
        });
    };
    fetchAccount();

    const { statusOptions } = useStudents();

    return {
      studentSecretInfo,
      statusOptions,
      fetchAccount,
      updateAccount,
      ssnShow,
    };
  },
};
</script>

<style lang="scss"></style>
