<template>
  <div>
    <!-- Header: Personal Info -->
    <!-- <div class="d-flex">
      <feather-icon icon="StudentIcon" size="19" />
      <h4 class="mb-0">Addresses</h4>
    </div> -->

    <!-- Form: Personal Info Form -->
    <b-form class="">
      <div class="simple-spinner" v-if="!isLoaded">
        <span></span>
      </div>
      <!-- Form: Personal Info Form -->
      <b-form ref="form" @submit.prevent="repeateAgain" v-else>
        <h5 v-if="addresses.length === 0" class="text-center pt-3 pb-3">No addresses listed</h5>

        <!-- Row Loop -->
        <b-row v-for="(item, index) in addresses" :id="item.id" :key="item.id" ref="row">
          <!-- Item Name -->
          <b-col lg="2" md="3" sm="12">
            <b-form-group label="Address Label" :label-for="`address-label-${index}`">
              <b-form-input
                :id="`address-label-${index}`"
                type="text"
                placeholder="Home Address"
                v-model="item.label"
                :disabled="item.label && item.label.startsWith('MyIP:')"
                @change="fixAddItem(index)"
              />
            </b-form-group>
          </b-col>

          <!-- Street 1 -->
          <b-col lg="3" md="4" sm="12">
            <b-form-group label="Street" :label-for="`address-street-${index}`">
              <b-form-input
                :id="`address-street-${index}`"
                type="text"
                placeholder="1600 Pennsylvania Avenue NW"
                v-model="item.address.street1"
              />
            </b-form-group>
          </b-col>

          <!-- City -->
          <b-col lg="2" md="3" sm="12">
            <b-form-group label="City" :label-for="`address-city-${index}`">
              <b-form-input :id="`address-city-${index}`" type="text" placeholder="Washington" v-model="item.address.city" />
            </b-form-group>
          </b-col>

          <!-- State -->
          <b-col lg="2" md="3" sm="12">
            <b-form-group label="State" :label-for="`address-state-${index}`">
              <b-form-input :id="`address-state-${index}`" type="text" placeholder="DC" v-model="item.address.state" />
            </b-form-group>
          </b-col>

          <!-- Zip -->
          <b-col lg="1" md="2" sm="12">
            <b-form-group label="Zip" :label-for="`address-zip-${index}`">
              <b-form-input :id="`address-zip-${index}`" type="text" placeholder="20500" minlength="5" v-model="item.address.zip" />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col lg="2" md="3" sm="12" class="mb-50">
            <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="mt-0 mt-md-2" @click="removeItem(index)">
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button variant="primary" class="float-right" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="doSaveChanges">
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>

          <!-- <b-form-checkbox v-model="submit.sync" class="float-right mr-5" style="margin-top: 7px" input-id="submit-sync" disabled>
            Sync
          </b-form-checkbox> -->

          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Add New</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BFormCheckbox } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onMounted, onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useStudents from '../../useStudents';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import studentStoreModule from '@/store/student/studentStoreModule';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    studentData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    studentData: function () {},
  },
  data: () => {
    return {
      submit: { sync: false },

      addresses: [],
      nextTodoId: 1,

      isLoaded: false,
    };
  },
  methods: {
    fixAddItem(index) {
      if (this.addresses[index].label.startsWith('MyIP:')) {
        this.addresses.splice(index, 1);
      }
    },
    doSaveChanges() {
      const pwd = this.studentData;
      const id = pwd.id;

      const parsed = this.addresses.filter((add) => {
        const { address: adr } = add;
        return adr.street1 && adr.city && adr.state;
      });

      if (parsed.length === 0) {
        return this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to edit student',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'No valid addresses listed',
          },
        });
      }

      store
        .dispatch('studentStoreModule/updateStudentProfile', {
          id,
          profileData: { addresses: parsed },
        })
        .then((response) => {
          this.loadProfile();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Student Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },

    repeateAgain() {
      this.addresses.push({ address: {} });
    },
    removeItem(index) {
      this.addresses.splice(index, 1);
    },

    loadProfile() {
      store.dispatch('studentStoreModule/fetchStudentProfile', { id: this.studentDataInfo.id }).then((response) => {
        this.addresses = response.data.addresses;
        console.log(this.addresses);
        this.isLoaded = true;
      });
    },
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },
  mounted() {
    this.loadProfile();
  },
  setup(props) {
    const studentDataInfo = ref(props.studentData);

    const { statusOptions } = useStudents();

    return {
      studentDataInfo,
      statusOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
