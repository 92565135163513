<template>
  <!-- Form: MyIP Abilities Form -->
  <b-form>
    <b-row class="mt-1">
      <!-- Field: Update Name Ability -->
      <b-col lg="2" md="5" xs="12">
        <b-form-group label-for="ability-update-name">
          <b-form-checkbox
            id="ability-update-name"
            :checked="hasAbility('UPDATE_NAME')"
            @change="toggleAbility('UPDATE_NAME')"
            switch
            inline
            class="custom-control-primary my-1"
          >
            Allow Update Name
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <!-- Field: Update Email Ability -->
      <b-col lg="2" md="5" xs="12">
        <b-form-group label-for="ability-update-email">
          <b-form-checkbox
            id="ability-update-email"
            :checked="hasAbility('UPDATE_EMAIL')"
            @change="toggleAbility('UPDATE_EMAIL')"
            switch
            inline
            class="custom-control-primary my-1"
          >
            Allow Update Email
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <!-- Field: Update Phone Number Ability -->
      <b-col lg="2" md="5" xs="12">
        <b-form-group label-for="ability-update-phone-number">
          <b-form-checkbox
            id="ability-update-phone-number"
            :checked="hasAbility('UPDATE_PHONE_NUMBER')"
            @change="toggleAbility('UPDATE_PHONE_NUMBER')"
            switch
            inline
            class="custom-control-primary my-1"
          >
            Allow Update Phone Number
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <!-- Field: Update Phone Number Ability -->
      <b-col lg="2" md="5" xs="12">
        <b-form-group label-for="ability-update-profile-picture">
          <b-form-checkbox
            id="ability-update-profile-picture"
            :checked="hasAbility('UPDATE_PROFILE_PICTURE')"
            @change="toggleAbility('UPDATE_PROFILE_PICTURE')"
            switch
            inline
            class="custom-control-primary my-1"
          >
            Allow Update Profile Picture
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-2 w-100">
      <b-col cols="12" lg="12">
        <b-button variant="primary" class="float-right" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="doSaveChanges">
          Save Changes
        </b-button>
        <b-button
          variant="outline-secondary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="$router.back()"
        >
          <feather-icon icon="ArrowLeftIcon" />
          Return
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BButton, BFormCheckbox } from 'bootstrap-vue';
import { onUnmounted, ref } from '@vue/composition-api';
import store from '@/store';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useStudents from '../../useStudents';
import studentStoreModule from '@/store/student/studentStoreModule';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BButton,
    ToastificationContent,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
  },
  metaInfo() {
    const title = this.studentData.fullName ? `${this.studentData.fullName} - Edit` : 'Edit Student';
    return {
      title,
    };
  },
  data: () => {
    return {
      submit: {
        sync: false,
      },
    };
  },
  methods: {
    hasAbility(ability) {
      return this.studentData.abilities.includes(ability);
    },
    toggleAbility(ability) {
      const index = this.studentData.abilities.indexOf(ability);
      if (index > -1) {
        this.studentData.abilities.splice(index, 1);
      } else {
        this.studentData.abilities.push(ability);
      }
    },
    doSaveChanges() {
      const pwd = this.studentData;
      const id = this.studentData.id;

      let saved = ['abilities'];

      for (const key of Object.keys(pwd)) {
        if (!saved.includes(key)) delete pwd[key];
        else if (pwd[key].value) pwd[key] = pwd[key].value;
      }

      store
        .dispatch('studentStoreModule/updateStudent', { id, studentData: pwd })
        .then((response) => {
          this.$emit('update:student-data', response.data);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Student Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
};
</script>

<style lang="scss"></style>
