<template>
  <div>
    <!-- Header: Personal Info -->
    <!-- <div class="d-flex">
      <h4 class="mb-0">Student Information</h4>
    </div> -->

    <!-- Form: Personal Info Form -->
    <b-form class="">
      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">
        <!-- Field: Student First Name -->
        <b-col cols="12" lg="3" md="6" xs="12">
          <b-form-group label="First Name" label-for="student-first-name">
            <b-form-input id="student-first-name" required v-model="studentDataInfo.firstName" />
          </b-form-group>
        </b-col>
        <!-- Field: Student Last Name -->
        <b-col cols="12" lg="3" md="6" xs="12">
          <b-form-group label="Last Name" label-for="student-last-name">
            <b-form-input id="student-last-name" required v-model="studentDataInfo.lastName" />
          </b-form-group>
        </b-col>
        <!-- Field: Student Email -->
        <!-- <b-col cols="12" lg="3" md="6" xs="12">
          <b-form-group label="Email" label-for="student-email">
            <b-form-input id="student-email" required v-model="studentDataInfo.email" />
          </b-form-group>
        </b-col> -->
        <!-- Field: Student Phone -->
        <!-- <b-col cols="12" lg="3" md="6" xs="12">
          <b-form-group label="Phone#" label-for="student-phone" description="Match Pattern +1##########">
            <b-form-input id="student-phone" v-model="studentDataInfo.phoneNumber" placeholder="+17035543827" />
          </b-form-group>
        </b-col> -->

        <!-- Field: Status -->
        <b-col cols="12" lg="3" md="6" xs="12">
          <b-form-group label="Status" label-for="student-status">
            <v-select v-model="studentDataInfo.status" :options="statusOptions" :clearable="false" required input-id="student-status" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2 w-100">
        <b-col cols="12" lg="12">
          <b-button variant="primary" class="float-right" :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="doSaveChanges">
            Save Changes
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="$router.back()"
          >
            <feather-icon icon="ArrowLeftIcon" />
            Return
          </b-button>

          <!-- <b-form-checkbox v-model="submit.sync" class="float-right mr-5" style="margin-top: 7px" input-id="submit-sync" disabled>
            Sync
          </b-form-checkbox> -->
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BFormCheckbox } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import { onUnmounted, ref } from '@vue/composition-api';
import vSelect from 'vue-select';
import store from '@/store';
import rosterStoreModule from '@/store/roster/rosterStoreModule';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import useStudents from '../../useStudents';
import studentStoreModule from '@/store/student/studentStoreModule';

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButton,
    ToastificationContent,
  },
  props: {
    studentData: {
      type: Object,
      required: true,
    },
  },
  metaInfo() {
    const title = this.studentData.fullName ? `${this.studentData.fullName} - Edit` : 'Edit Student';
    return {
      title,
    };
  },
  watch: {
    studentData: function () {
      this.inheritSelects();
    },
  },
  data: () => {
    return {
      submit: {
        sync: false,
      },
    };
  },
  methods: {
    inheritSelects() {
      if (this.studentData.status && !this.studentData.status.value) {
        this.studentDataInfo.status = this.statusOptions.filter((s) => s.value === this.studentData.status)[0];
      }
    },
    doSaveChanges() {
      const pwd = this.studentDataInfo;
      const id = pwd.id;

      let saved = ['firstName', 'lastName', 'status'];

      for (const key of Object.keys(pwd)) {
        if (!saved.includes(key)) delete pwd[key];
        else if (pwd[key].value) pwd[key] = pwd[key].value;
      }

      store
        .dispatch('studentStoreModule/updateStudent', { id, studentData: pwd })
        .then((response) => {
          this.studentDataInfo = response.data;
          this.$emit('update:student-data', response.data);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Student Edited!',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed to edit student',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response ? error.response.data.message : error.message,
            },
          });
        });
    },
  },
  mounted() {
    this.inheritSelects();
  },
  setup(props) {
    const studentDataInfo = ref(props.studentData);

    const { statusOptions } = useStudents();

    return {
      studentDataInfo,
      statusOptions,
    };
  },
};
</script>

<style lang="scss"></style>
